import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IAppointmentCreate, IAppointmentUpdate, IGoogleEventUpdate, ISplitProposalV2, IUserProfile, ITable, IMoveOwner} from '@/interfaces';
import { State } from '../state';
import { AppointmentState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetAppointment, commitSetAppointments, commitSetUnsyncedEvents, commitSetTable, commitSetSplitProposals, commitSetUnconfirmedAppointments} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { translateError } from '../utils';
import { ISendEAttestInput } from '@/interfaces/eattest';

type MainContext = ActionContext<AppointmentState, State>;

export const actions = {
    async actionGetAppointments(context: MainContext) {
        try {
            const response = await api.getAppointments(context.rootState.main.token, null, null, null);
            if (response) {
                commitSetAppointments(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAppointmentsWithStartAndEnd(context: MainContext, payload: {start: string, end: string}) {
        try {
            const response = await api.getAppointments(context.rootState.main.token, payload.start, payload.end, null);
            if (response) {
                commitSetAppointments(context, response.data);
                return response.data
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAppointmentsForPatient(context: MainContext, payload: {patient_id: number}) {
        try {
            const response = await api.getAppointments(context.rootState.main.token, null, null, payload.patient_id);
            if (response) {
                commitSetAppointments(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAppointmentHonorarium(context: MainContext, payload: {id: number}) {
        try {
            const response = await api.getAppointmentHonorarium(context.rootState.main.token, payload.id);
            if (response) {
                return response.data;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTable(context: MainContext, payload: { start: string, end: string }) {
        try {
            const response = await api.getAppointmentTable(context.rootState.main.token, payload.start, payload.end);
            if (response) {
                commitSetTable(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetSplitProposals(context: MainContext, payload: {table: ITable[], up: IUserProfile}) {
        async function rowToProposal(r) {
            console.log("row to split")
            console.log("table")
            const response = (await api.getSplitProposal(context.rootState.main.token, r.id)).data;
            const result: ISplitProposalV2 = {
              id: r.id,
              patient: r.patient,
              owner_email: r.owner_email,
              proposal1_start : response[0].start,
              proposal1_end : response[0].end,
              proposal2_start : response[1].start,
              proposal2_end : response[1].end
            }
            return result
          }
      
        const loadingNotification = { content: 'opslaan', showProgress: true };

        try {
            console.log("full table")
            console.log(payload.table)
            console.log(payload.up?.email)
            let splittableTableRowsTemp = (payload.table
                .filter(r => (r.duration == "60") && (r.owner_email == payload.up?.email) 
                )
            )
            console.log(splittableTableRowsTemp)
            const results = await Promise.all(splittableTableRowsTemp.map(rowToProposal).map(p => p.catch(e => e)));
            let splittableTableRows = results.filter(result => !(result instanceof Error));
            commitSetSplitProposals(context, splittableTableRows);
        } catch (error: any) {
            if (error.response && error.response.status == 400 && error.response.data["detail"] == "Appointment not found"){
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Afspraak niet gevonden', color: 'error' });
            }
            if (error.response && error.response.status == 400 && error.response.data["detail"] == "Not enough permissions"){
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Geen toegang tot afspraak', color: 'error' });
            }
            if (error.response && error.response.status == 400 && error.response.data["detail"] == "Only 60m appointments can be split"){
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Enkel afspraken van 60 minuten kunnen gesplitst worden', color: 'error' });
            }
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUnsyncedEvents(context: MainContext, payload: {performSync: boolean, start_date: string | null, end_date: string | null}) {
        try {
            if (payload.performSync){
                let pendingNotification = { content: 'Synchroniseren met Google ...', color: 'orange' }
                commitAddNotification(context, pendingNotification);
                const response = await api.syncEvents(context.rootState.main.token, payload.performSync, payload.start_date, payload.end_date);
                commitRemoveNotification(context, pendingNotification);
                if (response) {
                    commitSetUnsyncedEvents(context, response.data);
                }
            } else {
                const response = await api.syncEvents(context.rootState.main.token, payload.performSync, payload.start_date, payload.end_date);
                if (response) {
                    commitSetUnsyncedEvents(context, response.data);
                }
            }


        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateEvent(context: MainContext, payload: { id: number, event: IGoogleEventUpdate }) {
        const loadingNotification = { content: 'opslaan', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateEvent(context.rootState.main.token, payload.id, payload.event),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Afspraak bewerken', color: 'success' });
        } catch (error: any) {
            await commitRemoveNotification(context, loadingNotification);
            if (error.response && error.response){
                console.log("hi")
                let translated = translateError(error.response.data["detail"])
                console.log("hey")
                console.log(translated)
                commitAddNotification(context, { content: translated, color: 'error' });
            }
            else {
                await dispatchCheckApiError(context, error);
            }
        }
    },
    async actionUpdateAppointment(context: MainContext, payload: { id: number, appointment: IAppointmentUpdate }) {
        const loadingNotification = { content: 'opslaan', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateAppointment(context.rootState.main.token, payload.id, payload.appointment),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetAppointment(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Afspraak aangepast', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteAppointment(context: MainContext, payload: { id: number }) {
        try {
            const loadingNotification = { content: 'verwijderen', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteAppointment(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Afspraak verwijderd', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateAppointment(context: MainContext, payload: IAppointmentCreate) {
        const loadingNotification = { content: 'opslaan', showProgress: true };
        if (payload.start == "Invalid DateTime" || payload.end == "Invalid DateTime"){
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Afspraak maken mislukt - gelieve een geldige datum te kiezen', color: 'error' });
            return;
        }
        try {
            commitAddNotification(context, loadingNotification);
            const response = await api.createAppointment(context.rootState.main.token, payload);
            commitSetAppointment(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Afspraak aangemaakt', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            if (error.response && error.response && error.response.status == 400 && error.response.data["detail"] == "Prescription used up"){
                commitAddNotification(context, { content: 'Afspraak maken mislukt - voorschrift opgebruikt', color: 'error' });
            }
            else if (error.response && error.response.status == 400 && error.response.data["detail"] == "First appointment cannot be more than 2 months after prescription date"){
                commitAddNotification(context, { content: 'Afspraak maken mislukt - eerste afspraak voor een voorschrift kan niet meer dan 2 maanden na datum voorschrift liggen.', color: 'error' });
            }
            else if (error.response && error.response.status == 400 && error.response.data["detail"] == "Appointment cannot be more than 2 months after last prescription appointment"){
                commitAddNotification(context, { content: 'Afspraak maken mislukt - afspraak kan niet meer dan 2 maanden na laatste afspraak op voorschrift liggen.', color: 'error' });
            }
            else if (error.response) {
                commitAddNotification(context, { content: `Afspraak maken mislukt - ${error.response.data["detail"]}.`, color: 'error' });

            }
            else {
                await dispatchCheckApiError(context, error);
            }
        }
    },
    async actionChangeOwner(context: MainContext, payload: { id: number, targetOwnerid: number }) {
        const loadingNotification = { content: 'opslaan', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const data: IMoveOwner = {
                target_owner_id: payload.targetOwnerid
            }
            const response = await api.moveAppointment(context.rootState.main.token, payload.id, data);
            commitSetAppointment(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Afspraak verplaatst', color: 'success' });
        } catch (error: any) {
            commitRemoveNotification(context, loadingNotification);
            if (error.response) {
                commitAddNotification(context, { content: `Afspraak verplaatsen mislukt - ${error.response.data["detail"]}.`, color: 'error' });

            }
            else {
                await dispatchCheckApiError(context, error);
            }
        }
    },
    async actionSubmitProposal(context: MainContext, payload) {
        try {
            await api.submitSplitProposal(context.rootState.main.token, payload.id, payload.data);
        } catch (error: any) {
            if (error.response && error.response.status == 400 && error.response.data["detail"] == "Prescription used up"){
                commitAddNotification(context, { content: 'Afspraak maken mislukt - voorschrift opgebruikt', color: 'error' });
            }
            if (error.response && error.response.status == 400 && error.response.data["detail"] == "First appointment cannot be more than 2 months after prescription date"){
                commitAddNotification(context, { content: 'Afspraak maken mislukt - eerste afspraak voor een voorschrift kan niet meer dan 2 maanden na datum voorschrift liggen.', color: 'error' });
            }
            if (error.response && error.response.status == 400 && error.response.data["detail"] == "Appointment cannot be more than 2 months after last prescription appointment"){
                commitAddNotification(context, { content: 'Afspraak maken mislukt - afspraak kan niet meer dan 2 maanden na laatste afspraak op voorschrift liggen.', color: 'error' });
            }
            if (error.response && error.response.status == 400 && error.response.data["detail"].constructor == Object && "message" in error.response.data["detail"] && error.response.data["detail"]["message"] == "Prescription used up"){
                let patient = error.response.data["detail"]["patient"]
                commitAddNotification(context, { content: `Afspraak maken mislukt - voorschrift opgebruikt voor patient ${patient.first_name} ${patient.last_name}`, color: 'error', url: `/main/prescriptions/create?patient_id=${patient.id}` });
            }
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetUnconfirmedAppointments(context: MainContext) {
        try {
            const response = await api.getUnconfirmedAppointments(context.rootState.main.token);
            if (response) {
                commitSetUnconfirmedAppointments(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionConfirmUnconfirmedAppointment(context: MainContext, payload: { appointment_id: number, patient_id: number}) {
        try {
            const response = await api.confirmUnconfirmedAppointment(context.rootState.main.token, payload.appointment_id, payload.patient_id);
            if (response) {
                console.log("confirmed")
                console.log(response.data)
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionDeleteUnconfirmedAppointment(context: MainContext, id: number) {
        try {
            const response = await api.deleteUnconfirmedAppointment(context.rootState.main.token, id);
            if (response) {
                console.log("deleted")
                console.log(response.data)
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionHandlePaymentDialog(context: MainContext, payload) {
        try {
            await api.handlePaymentDialog(context.rootState.main.token, payload);
        } catch (error: any) {
            commitAddNotification(context, { content: error.response.data["detail"], color: 'error' });
        }
    },
    async actionCheckConflictUnconfirmed(context: MainContext, payload: {appointment_id: number}) {
        try {
            const response = (await Promise.all([
                api.checkConflictUnconfirmed(context.rootState.main.token, payload.appointment_id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return false
        }
    },
    async actionSendAttestation(context: MainContext, payload: ISendEAttestInput[]) {
        try {
            const response = (await Promise.all([
                api.sendEAttest(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];

            commitAddNotification(context, { content: `eAttest goedgekeurd`, color: 'success' });
            console.log(response.data)
            return response.data
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                let error_message_detail = error.response.data.detail.detail;
                // if (error.response.status == 400){
                //     error_message = parse_error_message(error.response.data.detail, request)
                // }
                console.log(error_message)
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
    async actionCancelEAttest(context: MainContext, payload: { 
        id: number      
    }) {
        try {
            const response = (await Promise.all([
                api.cancelEttest(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: `eAttest goedgekeurd`, color: 'success' });
        } catch (error: any) {
            if (error.response){
                let error_message = error.response.data.detail.message ?? "";
                if (error.response.status == 401){
                    error_message = "eHealth login ongeldig, gelieve opnieuw in te loggen op de Profiel-pagina."
                }
                let error_message_detail = error.response.data.detail.detail;
                commitAddNotification(context, { color: 'error', content: error_message, detail: error_message_detail });
            }
        }
    },
    async actionEmailAttest(context: MainContext, id: number) {
        try {
            const loadingNotification = { content: 'email verzenden', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.emailAttest(context.rootState.main.token, id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Factuur verzonden', color: 'success' });
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<AppointmentState, State>('');

export const dispatchCreateAppointment = dispatch(actions.actionCreateAppointment);
export const dispatchChangeOwner = dispatch(actions.actionChangeOwner);
export const dispatchGetAppointments = dispatch(actions.actionGetAppointments);
export const dispatchGetAppointmentsWithStartAndEnd = dispatch(actions.actionGetAppointmentsWithStartAndEnd);
export const dispatchGetAppointmentsForPatient = dispatch(actions.actionGetAppointmentsForPatient);
export const dispatchGetUnconfirmedAppointments = dispatch(actions.actionGetUnconfirmedAppointments);
export const dispatchGetTable = dispatch(actions.actionGetTable);
export const dispatchGetSplitProposals = dispatch(actions.actionGetSplitProposals);
export const dispatchGetUnsyncedEvents = dispatch(actions.actionGetUnsyncedEvents);
export const dispatchUpdateAppointment = dispatch(actions.actionUpdateAppointment);
export const dispatchDeleteAppointment = dispatch(actions.actionDeleteAppointment);
export const dispatchUpdateGoogleEvent = dispatch(actions.actionUpdateEvent);
export const dispatchSubmitProposal = dispatch(actions.actionSubmitProposal);
export const dispatchConfirmUnconfirmedAppointment = dispatch(actions.actionConfirmUnconfirmedAppointment);
export const dispatchDeleteUnconfirmedAppointment = dispatch(actions.actionDeleteUnconfirmedAppointment);
export const dispatchHandlePaymentDialog = dispatch(actions.actionHandlePaymentDialog);
export const dispatchCheckConflictUnconfirmed = dispatch(actions.actionCheckConflictUnconfirmed);
export const dispatchGetAppointmentHonorarium = dispatch(actions.actionGetAppointmentHonorarium);
export const dispatchSendAttestation = dispatch(actions.actionSendAttestation);
export const dispatchCancelEAttest = dispatch(actions.actionCancelEAttest);
export const dispatchEmailAttest = dispatch(actions.actionEmailAttest)