import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { NomenclatureState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetNomenclatures, commitSetNonConventionalHonoraria, commitSetNonConventionalHonorarium, commitRemoveNonConventionalHonorarium} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { INonConventionalHonorariumCreate } from '@/interfaces';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<NomenclatureState, State>;

export const actions = {
    async actionGetNomenclatures(context: MainContext) {
        try {
            const response = await api.getNomenclatures(context.rootState.main.token);
            if (response) {
                commitSetNomenclatures(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetNonConventionalHonoraria(context: MainContext) {
        try {
            const response = await api.getNonConventionalHonoraria(context.rootState.main.token);
            if (response) {
                console.log(response.data)
                console.log(response.data[0])
                commitSetNonConventionalHonoraria(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async createNonConventionalHonorarium(context: MainContext, payload: INonConventionalHonorariumCreate) {
        const loadingNotification = { content: 'opslaan', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createNonConventionalHonorarium(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetNonConventionalHonorarium(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Honorarium aangemaakt', color: 'success' });
        } catch (error) {
            if (error instanceof AxiosError && error.response){
                commitRemoveNotification(context, loadingNotification);
                console.log(error.response.data)
                if ("Duplicate generic duration" == error.response.data.detail){
                    commitAddNotification(context, { content: "Conflict met bestaand tarief", color: 'error' });
                } else {
                    commitAddNotification(context, { content: error.response.data, color: 'error' });
                }
            }
        }
    },
    async deleteNonConventionalHonorarium(context: MainContext, payload: { id: number }) {
        try {
            const loadingNotification = { content: 'verwijderen', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteNonConventionalHonorarium(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNonConventionalHonorarium(context, payload.id);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Honorarium verwijderd', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<NomenclatureState, State>('');

export const dispatchGetNomenclatures = dispatch(actions.actionGetNomenclatures);
export const dispatchGetNonConventionalHonoraria = dispatch(actions.actionGetNonConventionalHonoraria);
export const dispatchCreateNonConventionalHonorarium = dispatch(actions.createNonConventionalHonorarium);
export const dispatchDeleteNonConventionalHonorarium = dispatch(actions.deleteNonConventionalHonorarium);